<template>
  <router-link
    ref="link"
    class="group"
    :class="{
      'active': routeIsActive
    }"
    @click="onClick"
  >
    <div
      class="group"
    >
      <span
        class="indicator"
        :class="{
          'active': routeIsActive
        }"
      >
      </span>
      <span class="ml-2 text-lg group-hover:text-white">
        <slot />
      </span>
    </div>
  </router-link>
</template>

<script setup>
import { computed, useAttrs } from "vue"
import { useRoute } from "vue-router"

const attrs = useAttrs()
const route = useRoute()

const routeIsActive = computed(() => attrs?.to === route.hash)
const onClick = () => {
  const el = document.querySelector(attrs.to || "body")
  el.scrollIntoView({ behavior: "smooth" })
}
</script>

<style scoped>
.indicator {
  content: "";
  height: 1px;
  transition: width .3s;
  @apply w-10 bg-current group-hover:w-20 group-hover:bg-white group-hover:text-white group-hover:h-[2px] align-middle;
}

.active {
  @apply text-white w-20;
}
</style>