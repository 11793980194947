<template>
  <div class="fixed w-screen h-screen top-0 overflow-hidden hidden lg:block">
    <div
      class="absolute rounded-full w-96 h-96 bg-woodsmoke-900/30 pointer-events-none -translate-x-1/2 -translate-y-1/2 blur-3xl"
      :style="{
        left: `${x}px`,
        top: `${mouseY - scrollY}px`,
      }"
    >
    </div>
  </div>
</template>

<script setup>
import { useMouse } from "@vueuse/core"
import { useWindowScroll } from '@vueuse/core'

const { y: scrollY } = useWindowScroll()
const { x, y: mouseY } = useMouse()
</script>