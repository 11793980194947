<template>
  <div>
    <form
      method="POST"
      name="contact"
      data-netlify="true"
      data-netlify-recaptcha="true"
    >
      <input type="hidden" name="form-name" value="contact" />
      <div class="grid md:grid-cols-2 gap-4">
        <p>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Name"
          >
        </p>
        <p>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email *"
            required
          >
        </p>
      </div>
      <div class="mt-4">
        <p>
          <textarea
            name="subject"
            id="subject"
            cols="30"
            rows="10"
            placeholder="Subject*"
            class="textarea w-full"
            required
          ></textarea>
        </p>
      </div>
      <div id="captcha-wrapper" class="mt-4">
        <div data-netlify-recaptcha="true"></div>
      </div>
      <p class="text-center mt-4">
        <button type="submit" class="btn btn-primary md:btn-wide">Send</button>
      </p>
    </form>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';

onMounted(() => {
  const captcha = document.querySelector("#contact-form-captcha")
  const wrapper = document.querySelector("#captcha-wrapper")

  wrapper.appendChild(captcha)
})
</script>

<style scoped>
input {
  @apply input w-full;
}
</style>