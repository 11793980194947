import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./tailwind.css"
import { Icon } from '@iconify/vue';
import vueMatomo from 'vue-matomo'

createApp(App)
    .component("Icon", Icon)
    .use(store)
    .use(router)
    // .use(vueMatomo, {
    //     host: "https://matomo.erkancoban.com/",
    //     siteId: 1
    // })
    .mount('#app')
