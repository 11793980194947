<template>
  <div>
    <ul
      class="flex flex-wrap"
    >
      <template
        v-for="social in socials"
        :key="social.name"
      >
        <li class="p-2">
          <a
            v-if="social.url"
            :href="social.url"
            class="hover:text-white duration-500"
            target="_blank"
          >
            <Icon
              :icon="social.icon"
              width="30px"
              class="mx-auto"
            />
            <p v-if="social.label" class="capitalize font-semibold">
              resume
            </p>
          </a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script setup>
const socials = [
  {
    name: "github",
    url: "https://github.com/cocorocho",
    icon: "akar-icons:github-fill"
  },
  {
    name: "linkedin",
    url: "https://linkedin.com/in/erkan-coban",
    icon: "akar-icons:linkedin-box-fill"
  },
  {
    name: "resume",
    url: "/ErkanCoban-EN.pdf",
    icon: "material-symbols:lab-profile-outline",
    label: "resume"
  }
]
</script>
