<template>
  <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
    <div
      v-for="skill in skills"
      :key="skill"
      class="h-24"
    >
      <Icon :icon="skill[1]" color="red" class="grow w-full h-11"/>
      <h3 class="text-lg text-center uppercase font-semibold pt-1">
        {{ skill[0] }}
      </h3>
    </div>
  </div>
</template>

<script setup>
const skills = [
  ["python", "vscode-icons:file-type-python"],
  ["django", "logos:django-icon"],
  ["javascript", "logos:javascript"],
  ["typescript", "logos:typescript-icon"],
  ["vue", "vscode-icons:file-type-vue"],
  ["nuxt", "logos:nuxt-icon"],
  ["postgresql", "logos:postgresql"],
  ["html", "vscode-icons:file-type-html"],
  ["css", "devicon:css3"],
  ["docker", "vscode-icons:file-type-docker2"],
  ["traefik", "devicon:traefikproxy"],
]
</script>