<template>
  <div class="container relative mx-auto z-[1]">
    <div class="lg:flex">
      <div class="lg:basis-1/2 px-4">
        <header class="lg:sticky lg:top-0 lg:flex lg:flex-col lg:justify-between py-4 lg:py-24 lg:h-screen">
          <div>
            <router-link to="/">
              <h1 class="text-5xl font-bold text-white">
                Erkan Çoban
              </h1>
            </router-link>
            <h3 class="text-lg">
              Full Stack Developer
            </h3>
            <nav class="hidden lg:block pt-8">
              <ul>
                <li
                  v-for="section in sections"
                  :key="section.name"
                >
                  <Navigation :to="getSectionURL(section.id)">
                    <span class="uppercase">
                      {{ section.name }}
                    </span>
                  </Navigation>
                </li>
              </ul>
            </nav>
          </div>
          <Socials
            class="pt-8"
          />
        </header>
      </div>
      <div class="lg:basis-1/2 px-4">
        <main class="space-y-10 py-4">
          <template
            v-for="section in sections"
            :key="section.name"
          >
            <template
              v-if="section?.component"
            >
              <ContentSection
                :id="section.id"
                :header="section.header"
                :aria-label="section.header"
                :forceLabel="section?.forceLabel"
              >
                <component :is="section.component" />
              </ContentSection>
            </template>
          </template>
        </main>
      </div>
    </div>
  </div>
</template>

<script setup>

import Projects from "@/components/Projects.vue"

import About from "@/components/About.vue"
import Navigation from "@/components/Navigation.vue"
import Skills from "@/components/Skills.vue"
import ContentSection from "@/components/ContentSection.vue"
import Socials from "@/components/Socials.vue"
import Contact from "@/components/Contact.vue"

const sections = [
  {
    name: "home",
    component: undefined,
    id: undefined,
  },
  {
    name: "about",
    component: About,
    id: "about",
    header: "about",
  },
  {
    name: "skills",
    component: Skills,
    id: "skills",
    header: "skills"
  },
  {
    name: "projects",
    component: Projects,
    id: "projects",
    header: "projects"
  },
  {
    name: "contact",
    component: Contact,
    id: "contact",
    header: "contact",
    forceLabel: true
  }
]

const getSectionURL = (id) => id ? `#${id}` : ""
</script>
