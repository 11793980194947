<template>
  <div class="group/list flex flex-col gap-y-6">
    <a
      v-for="project in projects"
      :key="project.name"
      :href="project.projectURL"
      class="lg:hover:!opacity-100 lg:group-hover/list:opacity-30"
    >
      <div
        class="card glass md:card-side"
      >
        <figure class="md:w-2/5 p-2 md:p-4">
          <img
            :src="getThumbnailURL(project.thumbnail)"
            :alt="`${project.name} image`"
            srcset=""
            class="w-full"
          >
        </figure>
        <div class="card-body p-2 md:p-4 md:w-3/5">
          <p class="text-lg font-bold grow-0">{{ project.name }}</p>
          <p>
            {{ project.description }}
          </p>
          <div class="flex justify-between">
            <div class="flex basis-5/6 flex-wrap gap-1 items-end">
              <div
                v-for="tech in project.stack"
                :key="tech"
                class="badge"
              >
                {{ tech }}
              </div>
            </div>
            <a
              v-if="project.githubURL"
              :href="project.githubURL"
              class="cursor-pointer hover:scale-110 duration-200 ease-in-out"
            >
              <Icon icon="akar-icons:github-fill" width="32px" />
            </a>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script setup>
import { computed } from "vue"

const projects = [
  {
    name: "Weather App",
    stack: ["ASP.Net", "Nuxt", "Docker"],
    thumbnail: "weather-app.png",
    githubURL: "https://github.com/cocorocho/weather",
    projectURL: "https://weather.erkancoban.com",
    description: "Weather app using Open-Meteo API for real-time forecast"
  },
  {
    name: "E-Commerce",
    stack: ["Django", "Postgresql", "Nuxt", "PrimeVue", "Docker"],
    thumbnail: "e-commerce.png",
    githubURL: "https://github.com/cocorocho/django-ecommerce",
    projectURL: "https://ecommerce.erkancoban.com",
    description: "E-Commerce app built with Django, Django Rest Framework and Nuxt",
  }
]

const getThumbnailURL = (imageName) => require(`@/assets/${imageName}`)
</script>
