<template>
  <section
    ref="section"
    @click="onClick"
    class="transition duration-1000"
    :class="[
      sectionIsVisible
        ? 'opacity-100 translate-x-0 translate-y-0'
        : 'opacity-0 translate-x-0 translate-y-10'
    ]"
  >
    <h2
      class="lg:block text-2xl capitalize font-bold"
      :class="{
        'hidden': !forceLabel
      }"
    >
      {{ header }}
    </h2>
    <p>
      <slot />
    </p>
  </section>
</template>

<script setup>
import { ref } from "vue"
import { useIntersectionObserver } from "@vueuse/core";

const section = ref()
const sectionIsVisible = ref(false)
const props = defineProps({
  header: String,
  forceLabel: Boolean
})

const { stop } = useIntersectionObserver(
  section,
  ([{ isIntersecting }]) => {
    if (!sectionIsVisible.value && isIntersecting) sectionIsVisible.value = true
  }
)
</script>